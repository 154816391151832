.page-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 2%;
}
.section-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2%;

}