.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.upper-box {
    flex: 1;
    overflow-y: auto;
    /*background-color: #99aae5;*/
    padding: 3%;
    /*margin: 3%;*/
    height: 100%;
}

.lower-box {
    /*height: max-content;*/
    /*background-color: #f8faff;*/
    padding: 2% 2% 2% 2% ;
    bottom: 0;
}


.chat-box {
    padding: 16px;
    border-radius: 10px;
    box-shadow: #dfeaff 0px 0px 10px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.3s ease forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
