.truncate-overflow {
    --max-lines: 3;
    max-height: 10vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-size: 0.9em;
}

.history-box {
    padding:10px;
    background-color: #d6e4ff;
    border-radius:5px;
}

.history-container {
    overflow-y: scroll;
    height: 100%;
    overflow-anchor: revert;
}

