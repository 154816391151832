body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-button {
  background: #ffffff
}
::-webkit-scrollbar-track-piece {
  background: #ffffff
}
::-webkit-scrollbar-thumb {
  background: #f3f3f3
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Use viewport width units */
  height: 100vh; /* Use viewport height units */
  max-height: 100vh;
}

.sider-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sider-top {

  /*background-color: #99aae5;*/
  padding: 3% ;
  height: 5%;
}

.sider-middle {
  height: 87%;
  /*background-color: #f8faff;*/
  overflow-y: hidden;
  /*padding: 2% 2% 2% 2% ;*/
}

.sider-bottom {
  height: 10%;
  /*background-color: #f8faff;*/
  padding: 0 0;


}
