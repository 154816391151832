

.payment-page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    padding: 3%;
}

.payment-box{
    background-color: white;
    border-radius: 10px;
    box-shadow: #bcbcbc 0px 0px 10px;
    padding: 5%;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
    width: 70%;
    height: 85%;
    overflow-y: auto;
}